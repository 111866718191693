import { ref } from "vue"; 
import { AlertForm } from '@/types/alert_form';
import { AlertFormItem } from '@/types/alert_form_item';
import { AlertFormsRepository, ProjectIds  } from '@/repositories/AlertFormsRepository';
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic';
import { useRoute } from 'vue-router'
import { AlertFormNoticeSetting } from '@/types/alert_form_notice_setting';
import { AlertFormAndNoticeSetting } from '@/types/alert_form_and_notice_setting';


export const AlertFormsEditLogic = () => {
  const route = useRoute();
  const update_compleate = ref(false);
  const alertLoading = ref(false);

  const alertFormAndNoticeSetting = ref<AlertFormAndNoticeSetting>({
    alert_form_notice_settings: [{
      notice_type: '',
      targets: [],
    }],
    alert_form: {
      title: '',
      input_terms_type: '',
      input_terms_timings: [],
      alert_form_items: [],
    },
    project_ids: []
  })

  const projectIds = ref<ProjectIds>({
    project_ids: []
  })

  const alertFormNoticeSetting = ref<AlertFormNoticeSetting>({
    notice_type: '',
    targets: [],
  })

  const alertForm = ref<AlertForm>({
    title: '',
    input_terms_type: '',
    input_terms_timings: [],
    alert_form_items: [],
  })

  const addAlertFormItem = () => {
    const sortOrder = alertForm.value.alert_form_items.length;
    alertForm.value.alert_form_items.push({
      id: undefined,
      key: Math.random(),
      title:'',
      sort_order: sortOrder,
      alert_form_item_fields:[{
        id: undefined,
        key: Math.random(),
        title: '',
        required: false,
        threshold_min: undefined,
        threshold_max: undefined,
        sort_order: 0,
      }]
    });
  }

  const addAlertFormItemField = (alert_form_item: AlertFormItem) => {
    const sortOrder = alert_form_item.alert_form_item_fields.length;
    alert_form_item.alert_form_item_fields.push({
      id: undefined,
      key: Math.random(),
      title: '',
      required: false,
      threshold_min: undefined,
      threshold_max: undefined,
      sort_order:sortOrder,
    })
  }

  const removeAlertFormItem = (key: number) => {
    for (let i = 0; i < alertForm.value.alert_form_items.length; i++) {
      if (alertForm.value.alert_form_items[i].key == key) {
        alertForm.value.alert_form_items.splice(i, 1);
        break;
      }
    }
  }

  const removeAlertFormItemField = (alert_form_item: AlertFormItem, key: number) => {
    for (let i = 0; i < alert_form_item.alert_form_item_fields.length; i++) {
      if (alert_form_item.alert_form_item_fields[i].key == key) {
        alert_form_item.alert_form_item_fields.splice(i, 1);
        break;
      }
    }
  }

  const get = async (alert_id: number) => {
    alertLoading.value = true;
    const repository: AlertFormsRepository = new AlertFormsRepository();
    try {
      alertFormAndNoticeSetting.value = await repository.get(alert_id);
      alertFormNoticeSetting.value = alertFormAndNoticeSetting.value.alert_form_notice_settings[0];
      alertForm.value = alertFormAndNoticeSetting.value.alert_form;
      projectIds.value.project_ids = alertFormAndNoticeSetting.value.project_ids;
      alertLoading.value = false;
    } catch (e: any) {
      useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
    }
  }

  const alertFormUpdate = async (alert_form: AlertForm, alert_form_notice_setting: AlertFormNoticeSetting, project_ids: ProjectIds) => {
    const alert_id = Number(route.params.alert_form_id);
    const repository: AlertFormsRepository = new AlertFormsRepository();
    try {
      await repository.update(alert_id, alert_form, alert_form_notice_setting, project_ids);
      update_compleate.value = true;
    } catch (e: any) {
      useExceptionMessage(e, "失敗しました。再読み込みをしてお試し下さい");
    }
  }

  const up = (alertForm_key: number, alertForm_id: number) => {
    for (let i = 0; i < alertForm.value.alert_form_items.length; i++) {
      if(alertForm.value.alert_form_items[i].id == alertForm_id && alertForm_key == undefined){
        if (i == 0) {
          break;
        }
        alertForm.value.alert_form_items.splice(i - 1, 2, alertForm.value.alert_form_items[i], alertForm.value.alert_form_items[i - 1]);
        break;
      }
      if (alertForm.value.alert_form_items[i].key == alertForm_key && alertForm_key != undefined) {
        if (i == 0) {
          break;
        }
        alertForm.value.alert_form_items.splice(i - 1, 2, alertForm.value.alert_form_items[i], alertForm.value.alert_form_items[i - 1]);
        break;
      }
    }
  }

  const down = (alertForm_key: number, alertForm_id: number) => {
    for (let i = 0; i < alertForm.value.alert_form_items.length; i++) {
      if(alertForm.value.alert_form_items[i].id == alertForm_id && alertForm_key == undefined){
        if (i == alertForm.value.alert_form_items.length - 1) {
          break;
        }
        alertForm.value.alert_form_items.splice(i, 2, alertForm.value.alert_form_items[i + 1], alertForm.value.alert_form_items[i]);
        break;
      }
      if (alertForm.value.alert_form_items[i].key == alertForm_key && alertForm_key != undefined) {
        if (i == alertForm.value.alert_form_items.length - 1) {
          break;
        }
        alertForm.value.alert_form_items.splice(i, 2, alertForm.value.alert_form_items[i + 1], alertForm.value.alert_form_items[i]);
        break;
      }
    }
  }

  const AlertFormsEditReturn = () => {
    return {
      up,
      down,
      get,
      alertForm,
      alertLoading,
      alertFormUpdate,
      update_compleate,
      removeAlertFormItem,
      removeAlertFormItemField,
      addAlertFormItem,
      addAlertFormItemField,
      alertFormNoticeSetting,
      projectIds
    }
  }
  return {
    AlertFormsEditReturn,
    ...AlertFormsEditReturn()
  }
}